import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import AnimatedNumber from 'react-animated-number';

const styles = {
  dataLabel: {
    font: 'normal 18px Open Sans',
    fill: '#89a8bc',
    dominantBaseline: 'central',
  },
  smallNumber: {
    font: 'normal 24px Open Sans',
    dominantBaseline: 'central',
  },
};

function BracketText(props) {
  const { classes, labelX, labelY, label, value, color, format, inverted } = props;
  const { dataLabel, smallNumber } = classes;
  return (
    <>
      <text className={dataLabel} x={labelX} y={labelY} textAnchor={inverted ? 'start' : 'end'}>
        {label}
      </text>
      {value !== null ? (
        <AnimatedNumber
          className={smallNumber}
          x={labelX}
          y={labelY}
          textAnchor={inverted ? 'start' : 'end'}
          dy="22"
          fill={color}
          duration={1000}
          value={value}
          formatValue={format}
          component="text"
        />
      ) : null}
    </>
  );
}

BracketText.propTypes = {
  classes: PropTypes.object,
  color: PropTypes.string,
  label: PropTypes.string,
  labelX: PropTypes.number,
  labelY: PropTypes.number,
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  format: PropTypes.func,
  inverted: PropTypes.bool,
};

BracketText.defaultProps = {
  classes: {},
  color: '',
  label: '',
  labelX: 0,
  labelY: 0,
  value: 0,
  format: x => x,
  inverted: false,
};

export default withStyles(styles)(BracketText);
