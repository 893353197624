import React from 'react';
import PropTypes from 'prop-types';
import { Replay } from '@material-ui/icons';
import { Alert } from '@material-ui/lab';
import { Button } from '@material-ui/core';

export default function Loading( {stopLoading, error, info} ) {

  // If the close button is clicked we return to the previous state
  const handleLoading = () => {
    stopLoading();
  };

  return (
    <div style={{display: "flex"}}>
      <Alert variant="outlined" severity={error ? "error" : "info"}>{info}</Alert>
      {error ? <Button style={{marginLeft: "5px"}} onClick={handleLoading} startIcon={<Replay />}>Try again</Button> : null}
    </div>
  );
}

Loading.propTypes = {
    info: PropTypes.string.isRequired,
    error: PropTypes.bool.isRequired,
    stopLoading: PropTypes.func.isRequired
};