import React, { useState } from 'react';
import { Button, TextField } from '@material-ui/core';
import DoneOutlineIcon from '@material-ui/icons/DoneOutline';
import { Alert } from '@material-ui/lab';
import Loading from '../../../utilities/loading';
import { END_POINT } from '../../../config';

function HealthCheck() {
    const [loading, setLoading] = useState(false);
    const [loadingMsg, setLoadingMsg] = useState("Fetching data...");
    const [error, setError] = useState(false);
    const [hasResponse, setHasResponse] = useState(false);
    const [dynamodbMsg, setDynamodbMsg] = useState("");
    const [s3Msg, setS3Msg] = useState("");
    const [notifiersMsg, setNotifiersMsg] = useState("");
    const [dynamoProblem, setDynamoProblem] = useState(false);
    const [s3Problem, setS3Problem] = useState(false);
    const [notifiersProblem, setNotifiersProblem] = useState(false);
    const dynamodbErrorMessages = ['FAILED', 'INACCESSIBLE_ENCRYPTION_CREDENTIALS', 'ARCHIVING', 'UNKNOWN_TO_SDK_VERSION'];
    const notifiersErrorMessages = ['FAILED', 'NO WORKING NOTIFIERS', 'failed', 'insufficient-data'];
    const s3ErrorMessages = ['FAILED'];

    const stopLoading = () => {
        setLoading(false);
        setError(false);
        setLoadingMsg("Fetching data...");
    };

    const doHealthCheck = async () => {
        setLoading(true);
        const END_POINT_PATH = `${END_POINT}/awsHealthCheck`;
        const options = {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              "Accept": "application/json"
            },
        };

        const response = await fetch(`${END_POINT_PATH}`, options);

        if (response.ok) {
            const json = await response.json();
            setDynamodbMsg(json.dynamodb);
            setS3Msg(json.s3);
            setNotifiersMsg(json.notifiers);
            if (dynamodbErrorMessages.some(word => json.dynamodb.includes(word))) {
                setDynamoProblem(true);
            }
            if (s3ErrorMessages.some(word => json.s3.includes(word))) {
                setS3Problem(true);
            }
            if (notifiersErrorMessages.some(word => json.notifiers.includes(word))) {
                setNotifiersProblem(true);
            }
            setHasResponse(true);
            setLoading(false);
        } else {
            setError(true);
            setLoadingMsg("Failed to fetch data...")
        }

    };

    return (
        <div>
            { loading ? <Loading stopLoading={stopLoading} error={error} info={loadingMsg} /> : hasResponse ?
            <div style={{display: "flex", gap: "15px"}}>
               <div>
                    <Alert style={{marginBottom: "3px"}} variant="filled" severity={dynamoProblem ? "error" : "success"}>
                        DYNAMODB HEALTH CHECK
                    </Alert>
                    <TextField
                         multiline="true"
                         rows={25}
                         variant="outlined"
                         style={{width: "500px"}}
                         value={dynamodbMsg}
                     />
               </div>
               <div>
                    <Alert style={{marginBottom: "3px"}} variant="filled" severity={s3Problem ? "error" : "success"}>
                        S3 HEALTH CHECK
                    </Alert>
                    <TextField
                         multiline="true"
                         rows={25}
                         variant="outlined"
                         style={{width: "500px"}}
                         value={s3Msg}
                     />
               </div>
               <div>
                    <Alert style={{marginBottom: "3px"}} variant="filled" severity={notifiersProblem ? "error" : "success"}>
                        NOTIFIERS HEALTH CHECK
                    </Alert>
                    <TextField
                         multiline="true"
                         rows={25}
                         variant="outlined"
                         style={{width: "500px"}}
                         value={notifiersMsg}
                     />
               </div>
            </div> :
            <Button style={{marginBottom: "15px"}} variant="contained" size="large" color="primary" startIcon={<DoneOutlineIcon />} onClick={doHealthCheck}>
                Do a health check
            </Button> }
        </div>
    );

}

export default HealthCheck;