import React from 'react';
import PropTypes from 'prop-types';
import ReturnsChart from '../secondaryExhibits/results/returnsChart';
import PeriodBracketText from './periodBracketText';

export default function PeriodReturnChart(props) {
  const { portfolio, referenceReturn, staticChart, period } = props;
  return (
    <ReturnsChart
      portfolio={portfolio}
      reference={referenceReturn}
      period={period}
      width={300}
      height={75}
      barHeight={20}
      barRx={4}
      bracketOffset={5}
      textComponent={<PeriodBracketText />}
      staticChart={staticChart}
      noAxis
      fontSize="9px"
    />
  );
}

PeriodReturnChart.propTypes = {
  referenceReturn: PropTypes.number.isRequired,
  portfolio: PropTypes.number.isRequired,
  period: PropTypes.object.isRequired,
  staticChart: PropTypes.bool,
};

PeriodReturnChart.defaultProps = {
  staticChart: false,
};
