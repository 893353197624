import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

const styles = {
  dataLabel: {
    font: 'bold Open Sans',
    fill: '#89a8bc',
  },
};

function PeriodBracketText(props) {
  const { classes, labelX, topB, offsetB, label, value, color, format, inverted } = props;
  const { dataLabel } = classes;
  return (
    <text
      className={dataLabel}
      x={labelX}
      y={topB + offsetB}
      textAnchor="middle"
      dominantBaseline={inverted ? 'hanging' : 'baseline'}
    >
      <tspan>{label}</tspan>
      <tspan>: </tspan>
      <tspan fill={color}>{format(value)}</tspan>
    </text>
  );
}

PeriodBracketText.propTypes = {
  classes: PropTypes.object,
  color: PropTypes.string,
  label: PropTypes.string,
  labelX: PropTypes.number,
  topB: PropTypes.number,
  offsetB: PropTypes.number,
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  format: PropTypes.func,
  inverted: PropTypes.bool,
};

PeriodBracketText.defaultProps = {
  classes: {},
  color: '',
  label: '',
  labelX: 0,
  topB: 0,
  offsetB: 0,
  value: 0,
  format: x => x,
  inverted: false,
};

export default withStyles(styles)(PeriodBracketText);
